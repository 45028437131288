import React from 'react';
import {screen, render, waitFor} from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import QuickBillForm from '../QuickBillForm';
import * as ContactsService from '../customHooks';
import client from '../../../../lib/ajax';
const bankAccounts = [
  {
    name: 'Bbva Compass *****0000',
    bankName: 'BBVA Compass',
    trust: false,
    xid: 'bank_e3g1J5RBRpaYF8oHvNo94',
    accountNumber: '*****0000',
    achLimit: 0
  },
  {
    name: 'Trust Bancorp *****0001',
    bankName: 'Bancorp',
    trust: true,
    xid: 'bank_9Zq4ddK4m6eZGaAXqrQXX',
    accountNumber: '*****0001',
    achLimit: null
  },
  {
    name: 'Wells Fargo *****0000',
    bankName: 'Wells Fargo',
    trust: false,
    xid: 'bank_ECja7bEfSLTZ11QtsLOZd',
    accountNumber: '*****0000',
    achLimit: 5000
  }
];

describe('QuickBillForm', () => {
  const contacts = [
    {
      email: 'foo@bar.com',
      email_id: 'eml_ClEwijmLfCph57Wh5oTij',
      name: 'Foo Bar',
      id: 'p_Q7U09Db0PIVMRz3Xa2jwE'
    },
    {
      email: 'foo@baz.com',
      email_id: 'eml_7UFItowWzv7uGI3Ci1QZl',
      name: 'Foo Baz',
      id: 'p_G8TF2OXWEtz2u7Ty8BVdf'},
    {
      email: 'foo@buz.com',
      email_id: 'eml_FbZjRLmzeSsUYMRtuZs8T',
      name: 'buz',
      id: 'p_4SSXc8iFvpRa4bRy5nppF'
    }
  ];
  const requiredProps = {
    bankAccounts,
    subject: 'Request for Payment',
    body: 'For your convenience, our firm accepts secure, online payments.',
    referenceLabel: 'Reference',
    merchantId: 'a1b2c3',
    onSuccess: jest.fn(),
    onCancel: jest.fn()
  };
  beforeEach(() => {
    jest.spyOn(ContactsService, 'useFetchContacts').mockReturnValue({
      loading: false,
      data: contacts,
      error: undefined
    });
    jest.spyOn(client, 'post').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        json: jest.fn(() => Promise.resolve({}))
      })
    );
  });

  it('renders with expected fields', () => {
    render(<QuickBillForm {...requiredProps} />);
    expect(screen.getByText('Client Email')).toBeVisible();
    expect(screen.getByText('First Name (Optional)')).toBeVisible();
    expect(screen.getByText('Last Name (Optional)')).toBeVisible();
    expect(screen.getByText('Payment Amount')).toBeVisible();
    expect(screen.getByText('Deposit Account')).toBeVisible();
    expect(screen.getByText('Reference (Optional)')).toBeVisible();
    expect(screen.getByText('Subject Line')).toBeVisible();
    expect(screen.getByText('Custom Email Text')).toBeVisible();
    expect(screen.getByText('Add Attachment(s) (Optional)')).toBeVisible();
  });

  it('referenceLabel prop defaults to Reference if no value is passed', () => {
    render(<QuickBillForm {...requiredProps} referenceLabel={undefined} />);
    expect(screen.queryByText('Reference (Optional)')).toBeVisible();
  });

  it('labels the reference input with the passed referenceLabel prop', () => {
    render(<QuickBillForm {...requiredProps} referenceLabel={'Alternate Label'} />);
    expect(screen.queryByText('Reference (Optional)')).not.toBeInTheDocument();
    expect(screen.getByText('Alternate Label (Optional)')).toBeVisible();
  });

  it('bankAccounts prop defaults to an empty array if no value is passed', () => {
    render(<QuickBillForm {...requiredProps} bankAccounts={undefined} />);
    userEvent.click(screen.getByText('Select Deposit Account'));
    expect(screen.getAllByRole('listbox')[1].children.length).toBe(0);
  });

  it('populates passed bank accounts in Deposit Account dropdown', () => {
    render(<QuickBillForm {...requiredProps} />);
    userEvent.click(screen.getByText('Select Deposit Account'));
    expect(screen.getAllByRole('listbox')[1].children.length).toBe(3);
  });

  it('inputting an amount that exceeds limits on eCheck accounts will show a warning', async () => {
    render(<QuickBillForm {...requiredProps} />);
    expect(screen.queryByTestId('message-header')).not.toBeInTheDocument();
    userEvent.type(screen.getByText('Payment Amount'), '10000');
    userEvent.click(screen.getByText('Select Deposit Account'));
    userEvent.click(screen.getByText('Wells Fargo *****0000'));
    expect(screen.getByText('Payment amount exceeds single eCheck transaction limit.')).toBeVisible();
    expect(screen.getByTestId('warning-message')).toBeVisible();
    userEvent.type(screen.getByText('Payment Amount'), '{backspace}{backspace}{backspace}{backspace}');
    await waitFor(() => expect(screen.queryByTestId('message-header')).not.toBeInTheDocument());
  });

  it('default value for limits on eCheck accounts is $5000.00', async () => {
    render(<QuickBillForm {...requiredProps} />);
    expect(screen.queryByTestId('message-header')).not.toBeInTheDocument();
    userEvent.type(screen.getByText('Payment Amount'), '5000.01');
    userEvent.click(screen.getByText('Select Deposit Account'));
    userEvent.click(screen.getByText('Bbva Compass *****0000'));
    expect(screen.getByText('Payment amount exceeds single eCheck transaction limit.')).toBeVisible();
    expect(screen.getByTestId('warning-message')).toBeVisible();
    userEvent.type(screen.getByText('Payment Amount'), '{backspace}{backspace}{backspace}{backspace}');
    await waitFor(() => expect(screen.queryByTestId('message-header')).not.toBeInTheDocument());
  });

  it('removes eCheck warning if bank account is switched after respective account limit is exceeded', async () => {
    render(<QuickBillForm {...requiredProps} />);
    expect(screen.queryByTestId('message-header')).not.toBeInTheDocument();
    userEvent.type(screen.getByText('Payment Amount'), '5000.01');
    userEvent.click(screen.getByText('Select Deposit Account'));
    userEvent.click(screen.getByText('Wells Fargo *****0000'));
    expect(screen.getByText('Payment amount exceeds single eCheck transaction limit.')).toBeVisible();
    expect(screen.getByTestId('warning-message')).toBeVisible();
    userEvent.click(screen.getByText('Wells Fargo *****0000'));
    userEvent.click(screen.getByText('Trust Bancorp *****0001'));
    await waitFor(() => expect(screen.queryByTestId('message-header')).not.toBeInTheDocument());
  });

  it('selecting contact with first/last name from the email dropdown will populate respective fields', async () => {
    render(<QuickBillForm {...requiredProps} />);
    userEvent.type(screen.getByText('Client Email'), 'foo');
    userEvent.click(screen.getByText('Foo Bar foo@bar.com'));
    await waitFor(() => expect(screen.getAllByLabelText('First Name (Optional)')[0].value).toBe('Foo'));
    await waitFor(() => expect(screen.getAllByLabelText('Last Name (Optional)')[0].value).toBe('Bar'));
  });

  it('invalid email format will disable Send button and add error label to Client Email textbox', () => {
    render(<QuickBillForm {...requiredProps} />);
    const sendButton = screen.getByText('Send');
    expect(sendButton).toBeDisabled();
    userEvent.type(screen.getByText('Client Email'), 'foo');
    userEvent.type(screen.getByText('Payment Amount'), '1000');
    userEvent.click(screen.getByText('Select Deposit Account'));
    userEvent.click(screen.getByText('Bbva Compass *****0000'));
    expect(sendButton).not.toBeDisabled();
    userEvent.click(sendButton);
    expect(screen.queryByText('Client Email')).not.toBeInTheDocument();
    expect(screen.getByText('Client Email must be a valid email')).toBeVisible();
    expect(sendButton).toBeDisabled();
    userEvent.type(screen.getByText('Client Email must be a valid email'), '@bar.com');
    expect(screen.getByText('Send')).not.toBeDisabled();
  });

  it('submitting a good quick bill will invoke client.post with the correct values', async () => {
    const handleSuccess = jest.fn();
    jest.spyOn(client, 'post').mockImplementation(() =>
      Promise.resolve({
        status: 201,
        response: true,
        json: jest.fn(() => Promise.resolve({}))
      })
    );
    render(<QuickBillForm {...requiredProps} onSuccess={handleSuccess} />);
    userEvent.type(screen.getByText('Client Email'), 'foo@bar.com');
    userEvent.type(screen.getByText('Payment Amount'), '1000');
    userEvent.click(screen.getByText('Select Deposit Account'));
    userEvent.click(screen.getByText('Bbva Compass *****0000'));
    userEvent.click(screen.getByText('Send'));
    expect(screen.getByText('Send')).toBeDisabled();
    expect(client.post).toHaveBeenCalledTimes(1);
    expect(client.post).toHaveBeenCalledWith('/quick_bills', {
      amount: '1000.00',
      bank_account_id: 'bank_e3g1J5RBRpaYF8oHvNo94',
      body: 'For your convenience, our firm accepts secure, online payments.',
      email_address: 'foo@bar.com',
      first_name: '',
      last_name: '',
      merchant_id: 'a1b2c3',
      reference: '',
      subject: 'Request for Payment'
    });
    await waitFor(() =>  expect(handleSuccess).toHaveBeenCalledTimes(1));
  });

  it('submitting a quick bill resulting in a server error shows the error message', async () => {
    jest.spyOn(client, 'post').mockImplementation(() =>
      Promise.resolve({
        status: 500,
        response: true,
        json: jest.fn(() => Promise.resolve({}))
      })
    );
    render(<QuickBillForm {...requiredProps} />);
    userEvent.type(screen.getByText('Client Email'), 'foo@bar.com');
    userEvent.type(screen.getByText('Payment Amount'), '1000');
    userEvent.click(screen.getByText('Select Deposit Account'));
    userEvent.click(screen.getByText('Bbva Compass *****0000'));
    userEvent.click(screen.getByText('Send'));
    await waitFor(() =>  expect(screen.getByText('Error saving new Quick Bill information')).toBeVisible());
    await waitFor(() =>  expect(screen.getByText('An error occurred, please try again.')).toBeVisible());
  });

  it('submitting a quick bill resulting in an unproccessable error shows the error message', async () => {
    jest.spyOn(client, 'post').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        json: jest.fn(() => Promise.resolve({
          errors: {
            email_address: ['something blew up', 'kaboom']
          }
        }))
      })
    );
    render(<QuickBillForm {...requiredProps} />);
    userEvent.type(screen.getByText('Client Email'), 'foo@bar.com');
    userEvent.type(screen.getByText('Payment Amount'), '1000');
    userEvent.click(screen.getByText('Select Deposit Account'));
    userEvent.click(screen.getByText('Bbva Compass *****0000'));
    userEvent.click(screen.getByText('Send'));
    await waitFor(() =>  expect(screen.getByText('Error saving new Quick Bill information')).toBeVisible());
    await waitFor(() =>  expect(screen.getByText('Email Address something blew up, kaboom')).toBeVisible());
  });

  it('submitting a quick bill resulting in an unknowon status shows generic error message', async () => {
    jest.spyOn(client, 'post').mockImplementation(() =>
      Promise.resolve({
        status: 403,
        response: true,
        json: jest.fn(() => Promise.resolve({}))
      })
    );
    render(<QuickBillForm {...requiredProps} />);
    userEvent.type(screen.getByText('Client Email'), 'foo@bar.com');
    userEvent.type(screen.getByText('Payment Amount'), '1000');
    userEvent.click(screen.getByText('Select Deposit Account'));
    userEvent.click(screen.getByText('Bbva Compass *****0000'));
    userEvent.click(screen.getByText('Send'));
    await waitFor(() =>  expect(screen.getByText('Error saving new Quick Bill information')).toBeVisible());
    await waitFor(() =>  expect(screen.getByText('An error occurred saving the Quick Bill, please try again.')).toBeVisible());
  });
});